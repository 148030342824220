<template>
    <!-- login page  -->
    <v-app :style="colorVapp">
      <v-app-bar
        app
        elevation="0"
        color="#FFFFFF"
        style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
      >
        <v-toolbar-title class="pt-1">
          <img
            src="@/assets/logo_onebox-horizontal-png.png"
            width="95"
            height="40"
            alt="One Box"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-divider inset vertical></v-divider>
        <v-toolbar-items>
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab text v-on="on" small>
                <flag :iso="defaultflag" :squared="false" />
              </v-btn>
            </template>
            <v-list nav dense>
              <v-list-item
                v-for="(item, i) in language"
                :key="i"
                @click="changelang(item.data)"
              >
                <v-list-item-action>
                  <flag :iso="item.flag" :squared="false" />
                </v-list-item-action>
                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>
      <v-layout v-if="loadingpage === true"
        row
        wrap
        fill-height
        align-center
        justify-center
      >
        <v-flex lg6 xs11>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="100"
            ></v-progress-circular>
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-else-if="havepermission === true">
      <v-content style="background-color:#e8eff2;">
        <v-content>
          <v-layout v-if="loading === true"
            row
            wrap
            fill-height
            align-center
            justify-center
          >
            <v-flex lg6 xs11>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="100"
                ></v-progress-circular>
              </div>
            </v-flex>
          </v-layout>
          <v-container v-else style="overflow-y: hidden;" class="mt-n10">
            <v-layout row wrap justify-center align-center>
              <v-flex lg12 xs11>
                <v-card 
                  outlined
                  style="background-color: #f2faff"
                  class="elevation-2 px-8 py-6 rounded-xl"
                >
                  <v-list dense class="elevation-0 py-0" style="background-color: #f2faff">
                    <v-list-item class="text-left">
                      <v-btn
                        small
                        color="primary"
                        @click="backtopreventfolder()"
                        v-if="navigate.length > 1"
                        fab
                        class="mr-3"
                      >
                        <v-icon>keyboard_backspace</v-icon>
                      </v-btn>
                      <v-list-item-avatar class="text-center">
                        <v-avatar color="primary" size="35">
                          <v-icon dark size="20">folder</v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-breadcrumbs :items="navigate" divider=">">
                          <template v-slot:item="props">
                            <v-breadcrumbs-item
                              style="cursor: pointer"
                              @click="gotofolder(props.item.text[1])"
                            >
                              <span
                                style="
                                  color: #1565c0;
                                  font-size: 20px;
                                  font-weight: 700;
                                "
                                >{{ props.item.text[0] }}</span
                              >
                            </v-breadcrumbs-item>
                          </template>
                        </v-breadcrumbs>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-text v-if="loadingdata === true" class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="50"
                    ></v-progress-circular>
                  </v-card-text>
                  <v-card-text v-else id="thiscontainer_publicfolder" class="mt-n12">
                    <br />
                    <v-layout row wrap class="pt-0 pb-0">
                      <v-flex class="text-right mb-3">
                        <v-chip class="ma-2" color="primary" text-color="white">
                          <v-icon left>folder</v-icon>

                          {{ countfolder + " " + $t("folders") }}
                        </v-chip>
                        <v-chip class="ma-2" color="primary" text-color="white">
                          <v-icon left>mdi-file</v-icon>

                          {{ countfile + " " + $t("files") }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <div v-if="resolutionScreen < 500" class="mx-n6">
                      <v-list two-line class="mb-6 pb-6" style="height: 400px; overflow-y:auto" v-if="datafolder.length > 0">
                        <v-list-item
                          v-for="item in datafolder"
                          :key="item.title"
                          style="cursor: pointer"
                          @dblclick="loadfile_folder(item.data_id)"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              large
                              :color="item.file_icon[1]"
                              v-text="item.file_icon[0]"
                            ></v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.file_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="fn_calculate_size_new(item.file_size)"
                            ></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              :disabled="item.status_approve === 'Y' ? false : datenow < formatdatetime(item.time_approve) ? true : false "
                              class="elevation-0"
                              fab
                              small
                              @click="downloadfile(item)"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list style="height: 300px;" class="pt-12 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          style="font-size: 16px; font-weight: 700; line-height: 24px;"
                          class="text-center mt-8"
                        >
                          {{$t('tablefile.empty')}}
                        </v-list-item-title>
                      </v-list>
                    </div>

                    <div v-else>
                      <v-card
                        outlined
                        class="elevation-0 rounded-xl pt-5"
                        style="border-radius:10px"
                      > 
                        <v-data-table
                          v-model="selected"
                          :headers="headers"
                          :items="datafolder"
                          :single-select="singleSelect"
                          item-key="file_id"
                          :show-select="false"
                          :no-data-text="$t('tablefile.empty')"
                          class="elevation-1"
                          :page.sync="page"
                          :items-per-page="size"
                          :hide-default-footer="true"
                          @page-count="pageCount = $event"
                          height="400"
                          fixed-header
                        >
                          <template v-slot:[`header.data_type`]="{ header }">
                            <span
                              style="
                                color: #1565c0;
                                font-weight: 600;
                                font-size: 15px;
                              "
                              >{{ header.text }}</span
                            >
                          </template>
                          <template v-slot:[`header.data_name`]="{ header }">
                            <span
                              style="
                                color: #1565c0;
                                font-weight: 600;
                                font-size: 15px;
                              "
                              >{{ $t(header.text) }}</span
                            >
                          </template>
                          <!-- <template v-slot:[`header.file_date`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template> -->
                          <!-- <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template> -->
                          <!-- <template v-slot:[`header.file_count_in`]="{header}">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template> -->
                          <template v-slot:[`header.file_status`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template>
                          <template v-slot:item="props">
                              <tr
                                @dblclick="loadfile_folder(props.item.data_id)"
                                style="cursor: pointer"
                              >
                                <td class="text-center">
                                  <v-icon
                                    large
                                    :color="props.item.file_icon[1]"
                                    style="font-weight: 100"
                                    >{{ props.item.file_icon[0] }}</v-icon
                                  >
                                </td>
                                <td>{{ props.item.data_name }}</td>
                                <!-- <td>{{ setformatdate(props.item.file_date) }}</td> -->
                                <!-- <td class="text-center">
                                  {{ fn_calculate_size_new(props.item.file_size) }}
                                </td> -->
                                <!-- <td class="text-center">
                                  <div v-if="props.item.file_type === 'folder'">

                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <span
                                          style="font-size: 15px; color: #1a237e"
                                          v-on="on"
                                        >
                                          <v-icon
                                            class="mt-n1"
                                            size="20"
                                            color="indigo"
                                            >mdi-file-cloud</v-icon
                                          >
                                          {{ props.item.file_count_in }}
                                        </span>
                                      </template>
                                      <span>{{
                                        props.item.file_count_in + " " + $t("files")
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                </td> -->
                                <td class="text-center">
                                  <v-btn
                                    :disabled="props.item.status_approve === 'Y' ? false : datenow < formatdatetime(props.item.time_approve) ? true : false "
                                    class="elevation-0"
                                    fab
                                    small
                                    @click="downloadfile(props.item)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11>
                        <v-pagination
                          @input="changepage()"
                          v-model="page"
                          :length="pageCount || 0"
                          color="primary"
                          :total-visible="10"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-content>

        <!-- :folderdata="folder_share" -->
        <!-- <previewfile
          :show="openpreviewfile_folder"
          :filedata_1="currentfile_folder"
          :arrayfile="datafolder"
          userid="Shared link"
          @closepreviewfile="openpreviewfile_folder = false"
          @openpreviewfile="openpreviewfile_folder = true"
          :dataCitizenProfile="dataCitizenProfile"
        ></previewfile> -->

        <!-- snackbardowload -->
        <div class="text-center ma-2">
          <v-snackbar
            v-model="snackbardowload"
            bottom
            color="white"
            :timeout="timeout"
          >
            <v-list color="white" style="padding-top: 0px">
              <v-toolbar color="primary" dense short flat fab>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="snackbardowload = false"
                  fab
                  x-small
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list-item dense style="height: 40px; width: 400px">
                <v-list-item-content>
                  <v-list-item-title>{{ namedowload }}</v-list-item-title>
                </v-list-item-content>
                <v-progress-circular
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  color="teal"
                  style="margin-left: 25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular
                >
              </v-list-item>
            </v-list>
          </v-snackbar>
        </div>

        <!-- snackbardowloadfolder -->
        <div class="text-center ma-2">
          <v-snackbar
            v-model="snackbardowloadfolder"
            bottom
            color="white"
            :timeout="timeout"
          >
            <v-list color="white" style="padding-top: 0px">
              <v-toolbar color="primary" dense short flat fab>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="snackbardowloadfolder = false"
                  fab
                  x-small
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list-item dense style="height: 40px; width: 400px">
                <v-list-item-content>
                  <v-list-item-title>{{ namedowloadfolder }}</v-list-item-title>
                </v-list-item-content>
                <v-progress-circular
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  color="teal"
                  style="margin-left: 25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular
                >
              </v-list-item>
            </v-list>
          </v-snackbar>
        </div>
        <dialogsnackbermultidowload
          :show="opensnackbarmultidownload"
          @closedialog="opensnackbarmultidownload = false"
          :percent="percent"
          :namedowload="namedowload"
          :btsdownload="btsdownload"
        ></dialogsnackbermultidowload>
        <dialogsnackbardowload
          :show="opensnackbar"
          @closedialog="opensnackbar = false, $emit('opendragselect')"
          :filedata="file"
          :percen="newpercen"
          :name="namefile"
          :list="new_list"
          :btsdownload="btsdownload"
        ></dialogsnackbardowload>
      </v-content>      
      </v-layout>
      <v-layout v-else-if="accesslinkerror === true"
        row
        wrap
        fill-height
        align-center
        justify-center
      >
        <v-flex lg6 xs11>
          <div class="text-center">
            <v-card outlined style="border-radius: 25px">
              <br />
              <v-card-text>
                <v-icon size="100" medium color="red"
                  >mdi-folder-remove</v-icon
                >
              </v-card-text>
              <v-card-text v-if="accessexpirederror === true" style="font-size: 20px"
                >{{ $t("sharelinkuploadpage.linkexpired") }} </v-card-text
              >
              <v-card-text v-else-if="accesskeyerror === true" style="font-size: 20px"
                >{{ $t("sharelinkuploadpage.linkploblem") }} </v-card-text
              >
              <v-card-text
                v-else-if="accessnolink === true"
                style="font-size: 20px"
                >
                {{ $t("sharelinkuploadpage.nolink") }}
                </v-card-text
              >
              <v-card-text
                v-else-if="accessdisable === true"
                style="font-size: 20px"
                >
                {{ $t("sharelinkuploadpage.disablelink") }}
                </v-card-text
              >
              <v-card-text v-else style="font-size: 20px"
                >{{ $t("sharelinkuploadpage.linkploblem") }}</v-card-text
              >
              <br />
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <p class="pt-1">
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-actions>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-else
        row
        wrap
        fill-height
        align-center
        justify-center
      >
        <v-flex lg6 xs11>
          <div class="text-center">
            <v-card 
              v-if="!loadingpage"
              outlined 
              style="background-color: #FFFFFF"
              class="elevation-2 rounded-xl"
            >
              <br />
              <v-card-text>
                <v-icon size="100" medium color="#FFC107">mdi-folder</v-icon>
              </v-card-text>
              <v-card-text class="text-center" v-if="isExistProfile">
                <h2>{{ $t("username") }} : {{ username_show }}</h2>
              </v-card-text>
              <br />
              <v-layout align-center justify-center>
                <v-row align-items="center" justify="center" 
                no-gutters v-if="!isExistProfile">
                <v-col md="6" lg="8" xl="6" xs="12" sm="8" >
                  <v-tabs
                    show-arrows
                    fixed-tabs
                    background-color="primary"
                    dark
                    v-model="step1"
                  >
                    <!-- login username -->
                    <v-tab @click="fn_clicktabinfor()" :disabled="isSendOTP && !isTimeout">
                      <v-icon class="mr-1">mdi-account-box</v-icon>
                      Username
                    </v-tab>
                    <!-- login SMS -->
                    <v-tab @click="fn_clicktabphone()">
                      <v-icon class="mr-1">mdi-cellphone</v-icon>
                      SMS
                    </v-tab>
                  </v-tabs>
                  <div v-if="tab_username">
                    <v-card-text class="text-left">
                      <b>{{ $t("username") }}</b>
                      <v-form ref="usernameform">
                      <v-text-field
                        class="mt-2"
                        outlined
                        solo
                        flat
                        color="#174966"
                        prepend-inner-icon="account_box"
                        dense
                        v-model.trim="username"
                        :error-messages="usernameloginError"
                        @input="fn_tolowercase(), $v.username.$touch()"
                        @blur="$v.username.$touch()"></v-text-field>
                      </v-form>
                      <b>{{ $t("password") }}</b>
                      <v-form ref="passform">
                      <v-text-field
                        class="mt-2"
                        prepend-inner-icon="lock"
                        outlined
                        solo
                        color="#174966"
                        flat
                        dense
                        v-model.trim="password_login"
                        @click:append="showpassword = !showpassword"
                        :type="showpassword ? 'text' : 'password'"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="passwordloginError"
                        @input="fn_tolowercase(), $v.password_login.$touch()"
                        @blur="$v.password_login.$touch()"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                  </div>
                  <div v-if="tab_sms">
                    <div v-if="!isSendOTP">
                    <v-card-text class="text-left">
                    <b>{{ $t("loginSMS") }}</b>
                        <v-text-field
                          v-model="phonenumber"
                          class="mt-2"
                          outlined
                          solo
                          hide-details="auto"
                          flat
                          color="#174966"
                          prepend-inner-icon="phone"
                          dense
                          :disabled="loading"
                          :label="$t('phoneNumber')"
                          maxlength="10"></v-text-field>
                    </v-card-text>
                    </div>
                    <div v-else style="display: flex; flex-direction: column; align-items: center;">
                      <h3 class="text-center mt-4 mb-6">{{ $t("verifyOTP") }}</h3>
                      <otp-input
                        class="testotp"
                        ref="otpVerifyRefs"
                        @on-complete="onCompleteOTP"
                        @on-changed="onChangeOTP" 
                        :separateInputClass="otpclass"
                        :autoFocus="false"
                        activeWrapperClass="mai-finished"
                        placeholder=""
                        :isDisabled="isTimeout"
                        :isValid="false"
                        :digits="6"
                        type="text">
                      </otp-input>
                      <v-layout row wrap class="px-7 py-4" style="width: 100%">
                        <v-flex>
                          <div>{{ $t("countDown") }} {{ formattedCountdownLoginSMS }} </div>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex>
                          <span
                          v-if="isTimeout"
                          @click="fn_resendOTP()"
                          style="text-decoration: underline; cursor: pointer;"
                          >
                            {{ $t("resendOTP") }}
                          </span>
                          <span
                          v-else
                          >
                            REF: {{ refCode }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </v-col>
              </v-row>
              </v-layout>
              <v-layout
                lg12
                xs12
                row
                wrap
                align-center
                justify-center
                v-if="!isLogged"
                class="mt-4"
              >
                <v-flex lg3 xs3 style="min-width: 180px;">
                  <v-btn
                    v-if="tab_username"
                    block
                    rounded
                    color="primary"
                    class="white--text elevation-0"
                    :disabled="isloading || (username.length === 0 && password_login.length === 0) && !isExistProfile"
                    @click="fn_loginshare()"
                  >
                    <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                    {{ isExistProfile ? $t("publicpage.login") : tab_sms ? $t("otp_botonebox.send_otp_btn") : $t("publicpage.login") }}
                    <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                  </v-btn>
                  <div v-else>
                    <v-btn
                    v-if="!isSendOTP"
                    block
                    rounded
                    color="primary"
                    class="white--text elevation-0"
                    :disabled="isloading || (phonenumber.length === 0) || isSendOTP"
                    @click="fn_sendOTP()"
                  >
                    <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                    {{$t("otp_botonebox.send_otp_btn")}}
                    <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                    </v-btn>
                    <v-btn
                    v-else
                    block
                    rounded
                    color="primary"
                    class="white--text elevation-0"
                    :disabled="isTimeout"
                    @click="fn_loginshare()"
                  >
                    <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                    ยืนยัน OTP 
                    <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                  </v-btn>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                lg12
                xs12
                row
                wrap
                align-center
                justify-center
                v-if="!isLogged && isExistProfile"
                class="mt-3"
              >
                <v-flex lg3 xs3 style="min-width: 180px;">
                  <v-btn
                    block
                    rounded
                    color="error"
                    class="white--text elevation-0"
                    @click="fn_logout"
                  >
                    {{ $t("logout")}}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <p class="pt-1">
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-actions>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </v-app>
  </template>
  <script>
  import format from "date-fns/format";
  import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import { validationMixin } from "vuelidate";
  import { required, maxLength, email } from "vuelidate/lib/validators";
  import { mapState, mapGetters } from "vuex";
  import OtpInput from "otp-input-vue2";
  import VueCookies from "vue-cookies";
  import CryptoJS from "crypto-js";
  import  { CancelToken } from 'axios';
  const dialogsnackbermultidowload = () =>
  import("../components/optional/dialog-snackbarmultidowmload");
const previewpublicfile = () =>
  import("../components/optional/dialog-previewpublic");
const dialogsnackbardowload = () => import("../components/optional/dialog-snackbardownload");

// const previewfile = () =>
//   import("../components/optional/dialog-preview-public");
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    mixins: [validationMixin],
    validations: {
      phonenumber: { required },
      username: { required },
      password_login: { required },
    },
    components: {
      OtpInput,
      // previewfile,
      dialogsnackbermultidowload,
      previewpublicfile,
      dialogsnackbardowload
    },
    data: function() {
      return {
        datenow: format(new Date(), "yyyy-MM-dd"),
        total_data:0,
        navigate: [],
        file:"",
        new_list: [],
        namefile: "",
        newpercen: [],
        opensnackbar:false,
        listdatadowload:[],
        opensnackbarmultidownload:false,
        selected: [],
        btsdownload: false,
        percent: "",
        opensnackbarmultidownload:false,
        namedowloadfolder: "",
        namedowload: "",
        singleSelect: false,
        snackbardowloadfolder: false,
        percentCompleted: 0,
        namedowload: "",
        snackbardowload: false,
        timeout: 60000,
        currentfile_folder: {},
        openpreviewfile_folder:false,
        page: 1,
        size: 30,
        file_type:"",
        havepermission:false,
        loading: false,
        loadingpage: true,
        folder_name_: "",
        folder_id_: "",
        otp: "",
        timeInterval: 0,
        countDown : 0,
        check_memory: false,
        user_id: sessionStorage.getItem("user_id"),
        checklogin_cookie: (VueCookies.get("token") == null && sessionStorage.getItem("token") === null) || VueCookies.get("username") === null ? "false" : "true",
        checkIsvalidOTP: true, 
        showpassword: false,
        password_login: "",
        username: "",
        phonenumber: "",
        refCode: "",
        tab_sms: false,
        tab_username: true,
        step1: 0, // NEW 
        username_show: "", // NEW 
        isExistProfile: false, // MAI
        isLogged: false, // MAI
        isSendOTP: false, // NEW
        isloading: false, // NEW
        isTimeout: false, // NEW
        isDisableUpload: true,
        fail_: false,
        errormessage: "",
        defaultflag: "",
        language: [
          {
            flag: "us",
            name: "languages.english",
            data: "en",
          },
          {
            flag: "th",
            name: "languages.thai",
            data: "th",
          },
        ],
        userdata: {},
        checkhashtag: false,
        datahashtag: [],
        tempdatahashtag: [],
        hashtag: "",
        arraydataupload: [],
        temparraydataupload: [],
        openuploadlayout: false,
        statusuploading: false,
        totalstackfile: 0,
        createprogress: false,
        stackfileloading: false,
        statusstorage: false,
        checkorientation: false,
        filename: "",
        loading_profile: false,
        percentuploadedallfile: 0,
        statussuccess: false,
        remark: "",
        foldername: "",
        name_shared_eng: "",
        name_shared_th: "",
        business_shared_eng: "",
        business_shared_th: "",
        folderid: "",
        accesslinkerror: false,
        accessexpirederror: false,
        accessdisable: false,
        accesskeyerror: false,
        accessnolink: false,
        headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "data_type",
          width: "10%",
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "data_name",
          width: "80%",
          sortable: true,
        },
        // {
        //   text: "tablefile.date",
        //   value: "file_date",
        //   width: "15%",
        //   align: "left",
        //   sortable: true,
        // },
        // {
        //   text: "tablefile.filesize",
        //   value: "file_size",
        //   width: "23%",
        //   align: "center",
        //   sortable: true,
        // },
        // {
        //   text: "tablefile.count",
        //   value: "file_count_in",
        //   width: "11%",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "tablefile.download",
          value: "file_status",
          width: "10%",
          align: "left",
          sortable: false,
        },
      ],
      };
    },
    filters: {
      subStr: function(string) {
        if (string == null || string == "" || string == undefined) {
          return string;
        } else {
          if (string.length > 30) return string.substring(0, 30) + "...";
          else return string;
        }
      },
    },
    computed: {
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      pageCount: {
        get() {
          let l = this.total_data;
          let s = this.size;
          return Math.ceil(l / s);
        },
        set(newName) {
          return newName;
        },
      },
      ...mapGetters(["dataCitizenProfile", "dataAccountId","dataUsername"]),
      colorVapp() {
        // return "background-color: #cce4d4;";
        return "background-color: #e8eff2; overflow-y:hidden";
      },
      //style upload layout ของ mobile
      uploadLayout() {
        if (this.createprogress === true) {
          return "border-radius: 24px; cursor: not-allowed;";
        } else {
          return "border-radius: 24px; cursor: pointer;";
        }
      },
      // percentuploadedallfile() {
      //   return (อัปโหลดเฉพาะไฟล์รูป .jpeg .png .jpg
      //     this.temparraydataupload.reduce((a, b) => {
      //       let x = b["value"] === 500 ? 100 : b["value"];
      //       let sum = a + x;
      //       return sum;
      //     }, 0) / this.temparraydataupload.length
      //   );
      // },
      totalerror() {
        return (
          this.temparraydataupload.filter((item) => {
            return item.status === "fail";
          }).length || 0
        );
      },
      usernameloginError() {
        const errors = [];
        if (!this.$v.username.$dirty) return errors;
        !this.$v.username.required &&
          errors.push("โปรดระบุชื่อผู้ใช้ / Please fill Username");
        return errors;
      },
      passwordloginError() {
        const errors = [];
        if (!this.$v.password_login.$dirty) return errors;
        !this.$v.password_login.required &&
          errors.push("โปรดระบุรหัสผ่าน / Please fill Password");
        return errors;
      },
      otpclass() {
        if (this.checkIsvalidOTP === true) {
          console.log("True ");
          return "mai-classsed";
        } else {
          console.log("Falsde ");
          return "mai-classsed error-class";
        }
      },
      formattedCountdownLoginSMS() {
        const mins = ~~(this.countDown / 60);
        const secs = this.countDown % 60;
        return `${this.padTime(mins)}:${this.padTime(secs)}`;
      },
    },
    methods: {
      gotofolder(_folderid) {
      this.loadingdata = true;
      this.datafolder = [];
      this.page = 1;
      this.loadfile_folder(_folderid);
      },
      backtopreventfolder() {
        console.log("this.navigate",this.navigate);
      if (this.navigate.length === 1) {
        this.loadingdata = true;
        this.datafolder = [];
        this.page = 1;
        this.loadfile_folder(this.navigate[0]["text"][1]);
      } else {
        this.navigate.pop();
        this.loadingdata = true;
        this.datafolder = [];
        this.page = 1;
        this.loadfile_folder(
          this.navigate[this.navigate.length - 1]["text"][1]
        );
      }
    },
      // เปลี่ยนภาษา
      changelang(parameter) {
        localStorage.setItem("lang", parameter);
        this.$i18n.locale = parameter;
        let dflag = this.language.findIndex((v) => v.data === parameter);
        this.defaultflag = this.language[dflag]["flag"];
      },
      // กำหนดไอคอน
      seticon(parameter) {
        let dataicon;
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else if (parameter === "r" || parameter === "folder") {
          dataicon = ["mdi-folder", "#ffc107"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
  
        return dataicon;
      },
      //เคลียร์ข้อมูล
      async cleardata() {
        this.selectingimportFiles = false;
        this.fileImport = null;
        this.arraydataupload = [];
        this.temparraydataupload = [];
        this.statusuploading = false;
        this.totalstackfile = 0;
        this.statusstorage = false;
        this.datahashtag = [];
        this.tempdatahashtag = [];
        this.filename = "";
        this.percentuploadedallfile = 0;
        this.statussuccess = false;
        // this.business_shared_eng = "";
        // this.business_shared_th = "";
        // this.name_shared_th = "";
        // this.name_shared_eng= "";
        // this.remark = "";
        // this.folderid = "";
      },
    //   async fnGetDataSharelinkUpload() {
    //     let payload = {
    //       link: this.$route.query.id
    //     }
    //     let auth = await gbfGenerate.generateToken();
    //     let response = await this.axios({
    //       method: "POST",
    //       url:
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/get_shared_link_upload",
    //       data: payload,
    //       headers: { Authorization: auth.code },
    //     });
    //     try {
    //       if (response.data.status === "OK") {
    //         this.business_shared_eng = response.data.data.business_shared_eng;
    //         this.business_shared_th = response.data.data.business_shared_th;
    //         this.name_shared_th = response.data.data.name_shared_th;
    //         this.name_shared_eng= response.data.data.name_shared_eng;
    //         this.foldername = response.data.data.data_name;
    //         this.remark = response.data.data.remark;
    //         this.openuploadlayout = true;
    //         this.createprogress = false;
    //         this.folderid = response.data.data.data_id;
    //       } else {
    //         this.accesslinkerror = true;
    //         if (response.data.errorMessage === "Please input link" && response.data.errorCode === "ER404") {
    //           this.accessdisable = true;
    //         } else if (response.data.errorMessage === "link was expired" && response.data.errorCode === "ER403") {
    //           this.accessexpirederror = true;
    //         } else if (response.data.errorMessage === "This link was disable" && response.data.errorCode === "ER403") {
    //           this.accessdisable = true;
    //         } else if (response.data.errorMessage === "Key(s) missing (link)" && response.data.errorCode === "ER404") {
    //           this.accessdisable = true;
    //         } else {
    //           this.accesskeyerror = true;
    //         }
    //       }
    //     } catch (err) {
    //       Toast.fire({
    //         icon: "error",
    //         title: err,
    //       });
    //     }
    //   },
      // ล็อกอิน
      async fn_clicktabinfor () {
        clearInterval(this.timeInterval)
        this.isSendOTP = false
        this.isTimeout = false
        this.tab_username = true
        this.tab_sms = false
        this.otp = ""
        this.refCode = ""
        this.phonenumber = ""
      },
      async fn_clicktabphone () {
        this.tab_username = false
        this.tab_sms = true
        this.username = ""
        this.password_login = ""
        this.$v.username.$reset()
        this.$v.password_login.$reset()
      },
      async fn_sendOTP() {
        //check validate
        if (this.$v.phonenumber.$invalid && this.tab_sms) {
          this.$v.$touch();
          return
        }
  
        let payload = {
          mobile_no: this.phonenumber,
        };
        // console.log("payload mobile_no", payload);
        let auth = await gbfGenerate.generateToken();      
        this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/oauth_otp_login", payload, {
            headers: { Authorization: auth.code },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              this.isTimeout = false
              clearInterval(this.timeInterval)
              this.refCode = res.data.data.mobile_ref_id
              this.isSendOTP = true
              this.countDownTimer();
            } else if (res.data.errorMessage == "too many requests.") {
              console.log("error too many requests.", res.data);
              Toast.fire({
                icon: "error",
                title: this.$t("toast.tooManyRequests"),
              });
            } else {
              console.log("error Mobile_no not found", res.data);
              Toast.fire({
                icon: "error",
                title: this.$t('toast.mobileNumberIncorrect'),
              });
              // this.modelValue = '0';
            }
          })
          .catch((err) => {
            console.log('err', err);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
            // this.modelValue = '0';
          });
      },
      async fn_resendOTP () {
        if(this.isTimeout){
          this.fn_sendOTP()
        }
      },
      onCompleteOTP(otp) {
        this.otp = otp
      },
      onChangeOTP(otp) {
        this.otp = otp
      },
      fn_loginshare () {
        if (this.user_id !== "" && this.user_id !== null) {
          this.user_id = CryptoJS.AES.decrypt(this.user_id, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
          this.user_id = window.atob(this.user_id.toString(CryptoJS.enc.Utf8));
        }
        if(this.username === "" && this.password_login === "" && !this.tab_sms && !this.isExistProfile){
          console.log('ผ่านไไไ')
          this.$v.password_login.$dirty
          return
        }
        this.isloading = true
        if (this.checklogin_cookie == "true") {
          console.log("เข้านี้ ")
          this.check_memory = true;
          this.$store
            .dispatch("authorize_checking", this.user_id)
            .then((msg) => {
              // console.log(msg);
              this.loading = false;
              if (msg.status === "Access Granted") {
                console.log("this.check_button_login", this.check_button_login);
                if (this.check_memory == false) {
                  let userid = VueCookies.get("user_id");
                  // let userid = localStorage.getItem("user_id");
                  let token = VueCookies.get("token");
                  sessionStorage.setItem("user_id", userid);
                  sessionStorage.setItem("checklogin", false);
                  sessionStorage.setItem("token", token, 60 * 60 * 24);
                  sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                  localStorage.removeItem("user_id");
                  VueCookies.set("checklogin", false, 60 * 60 * 24);
                } else {
                  VueCookies.set("checklogin", true, 60 * 60 * 24);
                  // VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
                }
                this.isloading = false
                // login เสร็จ get ข้อมูลเพื่อดาวน์โหลด
                this.fn_get_data_download();
              
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
              });
            } else if (msg.code === "ER401") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
              });
              this.loader = false;
            } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
              Toast.fire({ 
                  icon: "error",
                  // title: msg.msg
                  // title: this.$t('toast.cannotconnectonebox')
                  title: this.$t('toast.expired_token')
                });
                this.logout();
  
            }else {
              console.log("เข้าอันนี้");
                Toast.fire({ 
                  icon: "error",
                  // title: msg.msg
                  // title: this.$t('toast.cannotconnectonebox')
                  title: this.$t("toast.expired_token"),
                });
                this.logout();
              }
            })
            .catch((err) => {
              console.log(err);
              Toast.fire({
                icon: "error",
                // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
                title: this.$t("toast.cannotconnectonebox"),
              });
              this.loading = false;
            });
        } else {
          let payload;
          VueCookies.set("check_btn_login_new", true);
          let apiPathLogin = 'authorize_login_nooneid'
          let loginType = "username"
          if (this.tab_username) {
            VueCookies.set("check_mohpromt", false);
            console.log("เข้า1");
            let usernamepass = {
              username: this.username,
              password: this.password_login,
              login_type: "username",
            };
            var encodeduserpass = btoa(JSON.stringify(usernamepass));
            if (
              VueCookies.get("user_en") === null ||
              VueCookies.get("user_en") === ""
            ) {
              payload = {
                data: encodeduserpass,
              };
            } else {
              payload = {
                data: VueCookies.get("user_en"),
              };
            }
            // decode กลับ
            var actual = JSON.parse(atob(encodeduserpass));
            console.log("actual", actual);
          }
          if (this.tab_sms) {
            payload = {
              mobile_no: this.phonenumber,
              otp: this.otp,
            };
            console.log("payload SMS", payload);
            apiPathLogin = 'authorize_login_mobile_otp'
            loginType = "sms"
          }
          this.$store
            .dispatch(apiPathLogin, payload)
            .then((msg) => {
              console.log("msg",msg);
              if (msg.status === "Access Granted") {
                // ส่ง event ไปยัง Google Analytics
                this.$gtag.event('login', {
                  event_category: loginType,
                });
                
                clearInterval(this.timeInterval)
                // localStorage.setItem("loginType", loginType);
                VueCookies.set("loginType", loginType);
                console.log("this.check_button_login", this.check_button_login);
                //  ล้อตาม login เดิม แบบ "ไม่จดจำฉัน"
                // let userid = localStorage.getItem("user_id");
                let userid = VueCookies.get("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                VueCookies.set("checklogin", false, 60 * 60 * 24);

                // login เสร็จ get ข้อมูลเพื่อดาวน์โหลด
                this.fn_get_data_download();
              
  
              } else if (msg.code === "LN001") {
                Toast.fire({
                  icon: "error",
                  // title: "Username หรือ Password ไม่ถูกต้อง"
                  title: this.$t(
                    "landingpage.notiloginandregister.incorrectusernameorpassword"
                  ),
                });
              } else if (msg.code === "ER401") {
                Toast.fire({
                  icon: "error",
                  title: this.$t(
                    "landingpage.notiloginandregister.incorrectusernameorpassword"
                  ),
                });
              } else if (msg.msg === "ER401: Authorization is wrong") {
                Toast.fire({
                  icon: "error",
                  title: this.$t(
                    "landingpage.notiloginandregister.incorrectusernameorpassword"
                  ),
                });
              } else if (msg.code === "ER001") {
                Toast.fire({
                  icon: "error",
                  // title: "Username หรือ Password ไม่ถูกต้อง"
                  title: this.$t(
                    "landingpage.notiloginandregister.emptyusernameorpassword"
                  ),
                });
              } else if (msg.code === "ER002") {              
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.otpMismatch"),
                });
              } else if (msg.code === "ER304") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
                });
              }else if(msg.code === "ER105"){
                Toast.fire({
                  icon: "error",
                  title: this.$t("otp_botonebox.invalid_otp"),
                });
              }else{
                Toast.fire({
                  icon: "error",
                  // title: msg.msg
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              this.isloading = false
            })
            .catch((err) => {
              console.log(err);
              Toast.fire({
                icon: "error",
                // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
                title: this.$t("toast.cannotconnectonebox"),
              });
              this.loading = false;
            });        
        }
      },
      async fn_get_data_download(){
      this.datafolder = [];
      this.loadingdata = true;
      let auth = await gbfGenerate.generateToken();
      let payload = {
        link: this.$route.query.id,
        account_id_rerquest: this.$route.query.account_id_rerquest,
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_data/rerquest_downloads",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.loadingdata = false;
            this.openuploadlayout = false;
            this.accesslinkerror = false;
            this.havepermission = true;
            console.log("response", response.data.data);
            this.file_type = response.data.data.data_type;
            if(this.file_type === 'folder'){
              this.countfolder = 1
              this.countfile = 0
            }else{
              this.countfolder = 0
              this.countfile = 1
            }
            console.log("this.file_type",this.file_type);
            console.log("response.data.data.data_type ",response.data.data.data_type );
            // var i;
            // for (i = 0; i < response.data.data.length; i++) {
              // if (response.data.data.data_type === "folder") {
                let data_folder = {};
                if (response.data.data.data_type === "folder") {
                  data_folder["file_icon"] = ["folder", "#ffc107"];
                } else {
                  data_folder["file_icon"] = this.seticon(response.data.data.data_type);
                }
                data_folder["data_id"] = response.data.data.data_id;
                data_folder["data_name"] = response.data.data.data_name;
                data_folder["data_type"] = response.data.data.data_type;
                data_folder["name_owner"] = response.data.data.name_owner;
                data_folder["requester_name"] = response.data.data.requester_name;
                data_folder["status_approve"] = response.data.data.status_approve;
                data_folder["time_approve"] = response.data.data.time_approve;
                
                this.datafolder.push(data_folder);
              // }
              console.log("this.datafolder",this.datafolder);
          } 
        });
      },
      getProfileuser () {
        // let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
        // let token_ = token ? window.atob(token) : "";
          this.$store
          .dispatch('authorize_checking')
          .then((msg) => {
            // this.loading = false
            this.loadingpage = false
            console.log("Message denied ", msg)
            if (msg.status === "Access Denied") {
              this.logout()
              this.isExistProfile = false
            } else {
              // this.getfiledetail_v_shareupload()
              this.isExistProfile = true
              if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
                this.username_show = CryptoJS.AES.decrypt(VueCookies.get("username"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
                this.username_show = window.atob(this.username_show.toString(CryptoJS.enc.Utf8));
              } else {
                this.logout()
              }
            }
          })
  
  
      },
      fn_tolowercase() {
        this.username = this.username.toLowerCase();
      },
      fn_logined () {
        // จะเด้งเข้า direct upload
        this.isLogged = true
      },
      padTime(time) { 
        //ถ้าเวลาเป็นเลขหลักเดียว จะเติม 0 ข้างหน้า
        return (time < 10 ? '0' : '') + time;
      },
      countDownTimer() {
        this.countDown = 5 * 2;
        this.timeInterval = setInterval(() => {
          if (this.countDown === 0) {
            this.isTimeout = true
            clearInterval(this.timeInterval)
          } else {
            this.countDown--
          }
        }, 1000)
      },
      checkLogoutAccount () {
        if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
          this.logout()
        }    
      },
      
      fn_logout () {
        this.checkLogoutAccount()
        // this.$store
        // .dispatch("authorize_expired")
        // .then((msg) => {
        //   console.log("Message Logout ", msg)
        //   this.isExistProfile = false
        // })
      },
      logout() {
        // this.$router.push({ path: "/logout_" });
        VueCookies.remove("token");
        VueCookies.remove("token_auth");
        VueCookies.remove("checklogin");
        VueCookies.remove("check_btn_login_new");
        VueCookies.remove("check_alert_email");
        sessionStorage.removeItem("biz_active");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("token_auth");
        sessionStorage.removeItem("checklogin");
        sessionStorage.removeItem("user_id");
        localStorage.removeItem("user_id");
        localStorage.removeItem("thai_email");
        localStorage.removeItem("username");
        // localStorage.removeItem("username_otp")
        localStorage.removeItem("token")
        VueCookies.remove("username_otp");
        VueCookies.remove("user_en");
        VueCookies.remove("continue_otp")
        VueCookies.remove("reason_otp")
        VueCookies.remove("checkpathmoppromt");
        VueCookies.remove("oneplatfrom");
        VueCookies.remove("check_citizen_oneplat");
        VueCookies.remove("feature_onlyoffice");
        VueCookies.remove("login_datetime")
        VueCookies.remove("data_id")
        VueCookies.remove("taxbox_check");
        VueCookies.remove("iconeva");
        this.checklogin_cookie = false
        this.check_memory = false
        this.showpassword = false
        this.password_login = ""
        this.username = ""
        this.phonenumber = ""
        this.tab_sms = false
        this.tab_username = true
        this.step1 = 0, // NEW 
        this.username_show = "" // NEW 
        this.isExistProfile = false // MAI
        this.isLogged = false // MAI
        this.isSendOTP = false // NEW
        this.openuploadlayout = false;
        // this.removeEvent(); // REMOVE DRAG EVENT
        this.cleardata();
      },
      async downloadfile(currentfile) {
        console.log("currentfile",currentfile);
      this.listerror =[];
      this.$emit("closecurrentonly");
      console.log("currentfile", currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size);
      this.btsdownload = true;
      let datadowload = {};
      this.newlist = [];
      this.listdatadowload.push(currentfile);


      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].data_id;
        datadowload["name"] = this.listdatadowload[i].data_name;
        datadowload["value"] = 0;
      }

      this.newlist.push(datadowload);

      this.percentCompleted = 0;
      if (currentfile.data_type === "folder") {
        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].data_name);
        }
        //this.btsdownload = true;
        this.opensnackbar = true;
        this.new_list = this.newlist;
        let timeout = 4000;

        let all_file = [];
        let zip_name = "";
        let url_link;

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/request/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" + 
          currentfile.data_id;


        this.axios
          .get(url, {})
          .then((response) => {
            if (response.data.status === "OK") {
              this.fn_loop_downloading(response.data.all_file, response.data.zip_name, response.data.url);
            } else {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.data_name + " นี้ได้",
              });
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbar = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.data_name + " นี้ได้",
            });
            console.log("err", error);
          });

      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.data_id,
        };
 
         let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v1/download_file_s3?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.data_id +
          "&account_id=" +
          this.dataAccountId

        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].data_name);
        }

          console.log(currentfile.status_encrypt);
          if(currentfile.status_encrypt === "Y"){      
            this.opensnackbar = true;
            this.new_list = this.newlist;
            let auth = await gbfGenerate.generateToken();
            console.log("น้อยกว่า 1 GB");
            this.axios
              .get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                  if (this.newlist[this.dataIndex]["value"] === 95) {
                    this.newlist[this.dataIndex]["value"] = 95;
                  } else {
                    this.newlist[this.dataIndex]["value"] = progresspercent;
                  }
                  this.newpercen = this.newlist[this.dataIndex]["value"];
                  this.namefile = this.newlist[this.dataIndex]["name"];
                },
                withCredentials: false,
                responseType: "arraybuffer",
              })
              .then((response) => {
                // ตรวจสอบว่า  API ทำงานถูกต้อง

                if (response.status === 200) {
                  try {
                    let type = currentfile.data_name.split(".");
                    type = type[type.length - 1].toLowerCase();
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                    if (res_error.status === "ER" && type !== "json") {
                      this.opensnackbar = false;
                      Toast.fire({
                        icon: "error",
                        title: res_error.errorMessage,
                      });
                    } else {
                      this.opensnackbar = true;
                      this.newlist[this.dataIndex]["value"] = 100;
                      this.btsdownload = false;

                      let headers = response.headers;
                      let blob = new Blob([response.data], { type: headers["content-type"] });
                      let link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = currentfile.data_name;
                      link.click();
                      setTimeout(() => {
                        this.opensnackbar = false;
                      }, 2500);
                    }
                  } catch (error) {
                    console.log("error", error);
                    this.opensnackbar = true;
                    this.newlist[this.dataIndex]["value"] = 100;
                    this.btsdownload = false;
                    let headers = response.headers;
                    let blob = new Blob([response.data], { type: headers["content-type"] });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentfile.data_name;
                    link.click();
                    setTimeout(() => {
                      this.opensnackbar = false;
                    }, 2500);
                  }
                } else {
                  this.opensnackbar = false;
                  Toast.fire({
                    icon: "error",
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                }
                this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
                this.newlist = []; //เคลียร์ array เป็น key แล้ว
                this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น

              })
              .catch((error) => {
                this.btsdownload = false;
                this.opensnackbar = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + currentfile.data_name + " นี้ได้",
                });
                console.log("err", error);
              });
          }else{
            console.log("เข้า status_encrypt = N");
            this.axios.get(url)
            .then(response => {
              console.log("=",response);
              // แก้เพราะดาวน์โหลดละ error
              if(response.data.url === undefined){
                window.location.href = response.config.url;
              }else{
                window.location.href = response.data.url;
              }
            }).catch(console.error)

          }
      }
      },
      async fn_loop_downloading(all_file, zip_name, url_link) {
        let total = 0;
        let res;
        if (all_file.length === 0) {
          this.newlist[this.dataIndex]["value"] = 100;
          console.log(url_link);
          window.location.assign(url_link);
          this.opensnackbar = false;
        } else {
          this.source = CancelToken.source()
          for (let i = 0; i < all_file.length; i++) {
            console.log("all_file.length", (1 / all_file.length) * 100, total);
            
            total += (1 / all_file.length) * 100;
              res = await this.downloading_folder(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              all_file[i],
              this.source.token
            );
            console.log(res);
            if (this.newlist[this.dataIndex]["value"] > 99) {
              this.newlist[this.dataIndex]["value"] = 99;
            } else {
              this.newlist[this.dataIndex]["value"] = Math.round(total);
            }
            this.newpercen = this.newlist[this.dataIndex]["value"];
            this.namefile = this.newlist[this.dataIndex]["name"];
            console.log(this.percentCompleted);
            // if(res.status === "OK" ){

            // }else{
            //   //continue;
            // }
            // console.log(i, all_file.length - 1);
            if (i === all_file.length - 1) {
              console.log("this.listerror",this.listerror);
              if(this.listerror.length == 0 ){
                this.newlist[this.dataIndex]["value"] = 100;
                console.log(url_link);
                window.location.assign(url_link);
                this.opensnackbar = false;
              }else{
                // console.log(this.listerror.length);
                let y ;
                for ( y = 0; y < this.listerror.length; y++) {
                  if (y === this.listerror.length - 1) {
                    this.newlist[this.dataIndex]["value"] = 100;
                    console.log(url_link);
                    window.location.assign(url_link);
                    this.opensnackbar = false;
                  }else{
                    res = await this.downloading_folder_again(
                      this.listerror[y]["file_id"],
                      this.listerror[y]["location_file"],
                      zip_name,
                      this.listerror[y]["path"],
                      this.listerror[y]
                    );
                  }
                  }


                // console.log("เข้าค่ะ");
                // let y ;
                // for ( y = 0; y < this.listerror.length; y++) {
                //   let res = await this.downloading_folder_again(
                //     this.listerror[y]["file_id"],
                //     this.listerror[y]["location_file"],
                //     zip_name,
                //     this.listerror[y]["path"],
                //     this.listerror[y]
                //   );

                //   }
                //   if (y === this.listerror.length - 1) {
                //     this.newlist[this.dataIndex]["value"] = 100;
                //     console.log(url_link);
                //     window.location.assign(url_link);
                //     this.opensnackbar = false;
                //   }
              }




              //            this.axios.get(url_link, {})
              //  .then((response) => {
              //   console.log(response);
              // });
            }
          }
        }
      },
      async downloading_folder_again(file_id, location_file, zip_name, all_file,file,token){
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/downloading_file?file_id=" +
            file_id +
            "&location_file=" +
            location_file +
            "&zip_name=" +
            zip_name +
            "&path=" +
            all_file +
            "&account_id=" +
            this.dataAccountId
          console.log(url);
          await this.axios
              .get(url)
              .then((response) => {
                console.log("response2",response);
                if (response.data.status === "OK") {
                    // this.total += Math.floor(100/this.total_allfile);
                } else {
                  this.source.cancel();
                  Toast.fire({
                    icon: "error",
                    title: response.data.filename + " " +":" + " " + response.data.errorMessage ,
                  });
                  this.opensnackbar = false;
                  this.btsdownload = false;
            
                }
              })
              .catch((error) => {
                if (this.countCancel === 0) {
                  // this.source.cancel();
                  Toast.fire({
                    icon: "error",
                    title: "ไม่สามารถดาวน์โหลดนี้ได้",
                  });
                  console.log("err", error);
                }
              });
          // let url =
          //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          //   "/api/downloading_file?file_id=" +
          //   file_id +
          //   "&location_file=" +
          //   location_file +
          //   "&zip_name=" +
          //   zip_name +
          //   "&path=" +
          //   all_file;

          // console.log(url);
          // return new Promise((resolve, reject) => {
          //   this.axios.get(url, {}).then((response) => {
          //     console.log("response",response);
          //     console.log("testtt", response.data.status);
          //     if(response.data.status === "OK"){          
          //         setTimeout(() => {
          //           resolve({
          //             status: response.data.status,
          //             msg: "",
          //           });
          //         }, 1);
          //     }else{
          //       this.source.cancel();
          //       setTimeout(() => {
          //         this.opensnackbar = false;
          //         }, 3000)
          //         Toast.fire({
          //           icon: "error",
          //           title: "ไม่สามารถดาวน์โหลดนี้ได้",
          //         });
          //     }
          //   });
          // });
      },
      async downloading_folder(file_id, location_file, zip_name, all_file,file,token) {
        console.log("downloading_file file_id",file_id);
        console.log("downloading_file location_file",location_file);
        console.log("downloading_file all_file",all_file);
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/downloading_file?file_id=" +
          file_id +
          "&location_file=" +
          location_file +
          "&zip_name=" +
          zip_name +
          "&path=" +
          all_file +
          "&account_id=" +
          this.dataAccountId
        console.log(url);
        await this.axios
            .get(url)
            .then((response) => {
              console.log("response 1",response);
              if (response.data.status === "OK") {
                  // this.total += Math.floor(100/this.total_allfile);
              } else {
                console.log("error 1");
                this.listerror.push(file);
                console.log("this.listerror",this.listerror);
              }
            })
            .catch((error) => {
              if (this.countCancel === 0) {
                // this.source.cancel();
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลดนี้ได้",
                });
                console.log("err", error);
              }
            });
        // let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //   "/api/downloading_file?file_id=" +
        //   file_id +
        //   "&location_file=" +
        //   location_file +
        //   "&zip_name=" +
        //   zip_name +
        //   "&path=" +
        //   all_file;

        // console.log(url);
        //  return new Promise((resolve, reject) => {
        //   this.axios.get(url, {}).then((response) => {
        //     console.log("response",response);
        //     console.log("testtt", response.data.status);
        //     if(response.data.status === "OK"){          
        //         setTimeout(() => {
        //           resolve({
        //             status: response.data.status,
        //             msg: "",
        //           });
        //         }, 1);
        //     }else{
        //       console.log("error 1");
        //       this.listerror.push(file);
        //       console.log("this.listerror",this.listerror);
        //     }

        //     // }else{
        //     //    return new Promise((resolve, reject) => {
        //     // setTimeout(() => {
        //     //       resolve({
        //     //         status: response.data.status,
        //     //         msg: "",
        //     //       });
        //     //     }, 1000);
        //     //       });
        //     // }
        //   });
        // });
      },
      async loadfile_folder(id) {
      this.datafolder = [];
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      console.log(cal_limit, cal_offset, this.size, this.page);
      if (cal_offset == 0) {
        this.datafolder = [];
      }
      let payload = {
        data_id: id,
        limit: cal_limit,
        offset: cal_offset,
      };

      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/share_link/get_data",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
          }
        );
        //console.log(response.data.status);
        if (response.data.status === "OK") {
          this.your_folder_id = response.data.your_folder[0]["folder_id"];
          console.log(response);
          this.loading = false;
          this.loadingdata = false;
          let checknavigate = this.navigate.findIndex((y) => y.text[1] === id);
          if (checknavigate === -1) {
            this.navigate.push({
              text: [
                response.data.your_folder[0]["folder_name"],
                response.data.your_folder[0]["folder_id"],
              ],
              disabled: false,
            });
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          console.log("b", this.navigate);
          this.countfolder = response.data.total_folder;
          this.countfile = response.data.total_file;
          this.total_data = response.data.total;
          for (let i = 0; i < response.data.data.length; i++) {
            let datafolder = {};
            datafolder["data_id"] = response.data.data[i]["id"];
            datafolder["data_name"] = response.data.data[i]["name"];
            datafolder["file_size"] = response.data.data[i]["size"];
            datafolder["file_date"] = response.data.data[i]["last_dtm"];
            datafolder["file_linkshare"] = "-";
            datafolder["file_count_in"] =
              response.data.data[i]["counf_file_in"];
            datafolder["last_dtm"] = "-";
            datafolder["file_status_share"] = "-";
            datafolder["priority"] = response.data.data[i]["priority"];
            datafolder["file_status_sharelink"] = "-";
            datafolder["file_status"] = "-";
            datafolder["file_owner_eng"] = "-";
            datafolder["file_owner_th"] = "-";
            // datafolder["status_watermark"] = this.folder_share["status_watermark"];
            if (response.data.data[i]["data_type"] === "folder") {
              datafolder["data_type"] = "folder";
              datafolder["file_icon"] = ["folder", "#ffc107"];
            } else {
              datafolder["data_type"] = response.data.data[i]["data_type"];
              datafolder["file_icon"] = this.seticon(
                response.data.data[i]["data_type"]
              );
            }

            this.datafolder.push(datafolder);
          }
          console.log("datafolderdatafolder",this.datafolder);
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        }
      } catch (ex) {
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
        console.log(ex);
      }
      },
      formatdatetime(_datetime) {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      },
  
    },
    async mounted() {
      // this.loginwithlogindirecupload();
      this.cleardata();
      await this.getProfileuser()
      // this.openuploadlayout = true;
    },
    // created() {
    //   // this.addEvent();
    //   this.addEventOrientation();
    //   this.changelang(localStorage.getItem("lang"));
    // },
    // beforeDestroy() {
    //   this.removeEvent();
    //   this.cleardata();
    //   this.createprogress = false;
    // },
  };
  </script>
  <style scoped>
  .drag-selector {
    padding: 15px;
  }
  .drag-selector__item {
    align-items: center;
  }
  template td {
    padding: 0px;
    margin: 0px;
  }
  .v-snack__content {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  #layouthover {
    background: #e8eff2;
    border-radius: 25px;
    transition: background 200ms, opacity 200ms, height 175ms, width 175ms,
      border 300ms;
    opacity: 1;
    height: 305px;
  }
  #layouthovermobile {
    background: #e8eff2;
    border-radius: 25px;
    transition: background 200ms, opacity 200ms, height 175ms, width 175ms,
      border 300ms;
    opacity: 1;
    height: 240px;
  }
  
  div#dropzone {
    border: 4px dashed #5fb588;
    font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
    background: #e8eff2;
  }
  #zonefile {
    border: 4px dashed #5fb588;
    font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
    border-radius: 24px;
    background: #e8eff2;
  }
  
  #inputfile {
    border-radius: 23px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }
  
  #titleTextUploadtitle {
    color: black;
    font-size: 18px;
    line-height: 24px;
  }
  #titleTextUploadsubtitle {
    color: red;
    font-size: 16px;
    line-height: 24px;
  }
  .checkboxStyle .v-label {
    font-size: 14px;
    color: black;
  }
  .textFieldClass .v-label {
    font-size: 14px;
    display: flex !important;
  }
  .vue-otp-input >>> .mai-classsed {
    text-align: center;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border: solid 1px rgba(128, 128, 128, 0.7);
    /*  */
  }
  .vue-otp-input >>> .error-class {
    border: solid 2px rgba(255, 125, 125, 255);
  }
  /* SCREEN SIZE XS  */
  @media screen and (max-width: 350px) {
    .vue-otp-input >>> .mai-classsed {
      width: 30px;
      height: 35px;
    }
  }
  @media (min-width: 350px) and (max-width: 370px) {
    .vue-otp-input >>> .mai-classsed {
      width: 35px;
      height: 35px;
    }
  }
  
  @media (min-width: 370px) and (max-width: 385px) {
    .vue-otp-input >>> .mai-classsed {
      width: 38px;
      height: 35px;
    }
  }
  
  @media screen and (min-width: 600px) {
    .vue-otp-input >>> .mai-classsed {
      width: 48px;
      height: 40px;
    }
  }
  </style>
  